.btn {
  @apply flex items-center justify-center rounded-xl !bg-accent stroke-secondary px-7 py-5 text-center text-sm font-semibold uppercase leading-none tracking-wider text-text-secondary hover:!bg-accent-hover;
}

.btn[disabled] {
  @apply pointer-events-none !bg-select-bg text-text-secondary;
}

.btn_outline {
  @apply border-min border-accent !bg-transparent text-text-primary hover:text-text-secondary;
}

.btn_pale {
  @apply !bg-secondary stroke-text-primary text-text-primary hover:!bg-accent hover:stroke-secondary hover:text-text-secondary;
}

.btn_big {
  @apply py-4 text-sm font-semibold;
}

.btn_active {
  @apply !bg-accent text-text-secondary;
}

.btn_dark {
  @apply !bg-text-pale text-text-secondary;
}

.btn_danger {
  @apply !bg-danger text-text-secondary hover:!bg-danger-hover;
}

.btn_dangerOutline {
  @apply border border-danger !bg-transparent text-text-pale hover:!bg-danger;
}

.btn_ton {
  @apply flex items-center justify-center rounded-xl !bg-ton stroke-secondary px-7 py-5 text-center text-sm font-semibold uppercase leading-none tracking-wider text-text-secondary hover:!bg-ton;
}

.underlined-btn {
  @apply relative pb-3 text-center text-2xl font-bold opacity-50 before:absolute before:bottom-0 before:left-1/2 before:hidden before:h-1 before:w-8 before:-translate-x-1/2 before:rounded-tw before:bg-accent;
}

.underlined-btn_active {
  @apply opacity-100 before:block;
}

/* NEW */
.btn-base {
  @apply flex cursor-pointer items-center justify-center rounded-xl transition-colors;
}

.btn-base:disabled {
  @apply cursor-not-allowed opacity-40;
}

.btn-primary {
  @apply btn-base;
  @apply !bg-green-400 font-bold text-white hover:!bg-green-600 disabled:!bg-green-400;
}

.btn-primary-dark {
  @apply btn-base;
  @apply border-gray-900 !bg-gray-900 text-white hover:border-green-400 hover:!bg-green-600 active:outline active:outline-black;
}

.btn-primary-danger {
  @apply btn-base;
  @apply border-red-400 !bg-red-400 text-white hover:!bg-black active:outline active:outline-black;
}

.btn-outline {
  @apply btn-base;
  @apply border-green-400 !bg-transparent text-green-400 hover:!bg-green-600 hover:text-white active:outline active:outline-black;
}

.btn-outline-danger {
  @apply btn-base;
  @apply border-red-400 !bg-transparent text-red-400;
}

.btn-small {
  @apply px-4 py-2;
}

.btn-medium {
  @apply px-6 py-3 text-base max-md:text-xs;
}

.btn-flat {
  @apply flex items-center justify-center gap-x-2 border-0 bg-transparent p-0 text-base text-slate-400 transition-colors hover:text-black disabled:cursor-not-allowed disabled:text-slate-400 dark:text-slate-200 dark:hover:text-white;
}

.btn-flat_danger {
  @apply text-red-400 hover:text-red-500;
}

.btn-text {
  @apply btn-base;
  @apply border-0 bg-transparent text-green-400 hover:underline;
}

.btn-text-danger {
  @apply btn-base;
  @apply border-0 bg-transparent text-red-400 hover:underline;
}

.btn-secondary {
  @apply btn-base;
  @apply bg-gray-100 font-semibold text-text-secondary hover:text-text-primary dark:bg-slate-700 dark:hover:text-white;
}
