.title {
  @apply text-2xl font-semibold text-text-primary dark:text-white;
}

.subtitle {
  @apply text-xl font-semibold text-text-primary dark:text-white;
}

.half-title {
  @apply text-2xl font-semibold text-black dark:text-white;
}

.altitle {
  @apply text-lg font-medium text-black dark:text-white;
}

.title-big {
  @apply text-xl font-normal text-text-primary dark:text-white;
}

.title-modal {
  @apply text-center text-2xl font-bold;
}
