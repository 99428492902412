@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100..900&display=swap");

@import url("./app/styles/_fonts.css");
@import url("./app/styles/_buttons.css");
@import url("./app/styles/_forms.css");
@import url("./app/styles/_titles.css");
@import url("./app/styles/_tables.css");

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 16px;

  @media (min-width: 769px) and (max-width: 1440px) {
    font-size: 1.111vw;
  }
}

body {
  background-color: #fff;
  margin: 0;
  height: 100%;
  @apply font-primary text-text-primary dark:bg-black dark:text-gray-25;
}

@layer components {
  .container {
    @apply mx-auto max-w-full px-[6.25rem] max-md:px-4;
  }

  .main-container {
    @apply mx-auto max-w-[58.75rem] px-9 max-md:max-w-full max-md:px-4;
  }

  .landing-container {
    @apply mx-auto max-w-[107.5rem] px-[6.25rem] max-md:max-w-full max-md:px-4;
  }

  .box {
    @apply rounded-main border border-stroke-color bg-box-background p-5 dark:border-slate-700 dark:bg-slate-800;
  }

  .dot {
    @apply inline-block h-2 w-2 rounded-full bg-text-pale;
  }

  .divider {
    @apply h-[0.0625rem] border-none bg-stroke-color dark:bg-gray-700;
  }

  .admin-body {
    @apply flex flex-col space-y-6;
  }

  .custom-tooltip {
    @apply max-w-[12rem] rounded-main bg-accent px-2 py-1 text-center text-sm text-text-secondary;
  }

  .bg-gradient {
    background: conic-gradient(from 39.91deg at 50% 50%, #317544 0deg, #2bc157 360deg);
  }

  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  /* For ng-bootstrap dropdown */
  .dropdown {
    @apply relative block;
  }
  .dropdown > .dropdown-toggle {
    @apply cursor-pointer;
  }
  .dropdown > .dropdown-menu {
    @apply hidden w-fit min-w-[6.3rem] overflow-hidden rounded-xl border border-gray-100 bg-white shadow dark:border-slate-700 dark:bg-slate-800;
  }
  .dropdown .dropdown-item {
    @apply flex w-full cursor-pointer items-center gap-x-2 px-4 py-2 text-sm font-semibold text-text-secondary hover:bg-gray-100 hover:text-text-primary dark:hover:bg-slate-700 dark:hover:text-white;
  }
  .dropdown > .dropdown-menu.show {
    @apply z-10 block;
  }
}

.modal-backdrop {
  @apply fixed inset-0 !z-[100] bg-black/70;
}

.modal-backdrop.show {
  display: block;
}

.modal {
  @apply fixed inset-0 z-[105] hidden items-end overflow-auto md:items-center md:justify-center;
}

.modal.show {
  display: flex;
}

.modal-dialog {
  @apply relative w-screen rounded-t-2xl bg-white dark:border dark:border-slate-700 dark:bg-slate-900 md:w-auto md:min-w-[35rem] md:rounded-2xl;
}

.modal-dialog_full-content {
  @apply p-0 max-md:rounded-none;
}

.modal-dialog_full-content > .modal-content {
  @apply max-md:h-screen max-md:max-h-screen;
}

.modal-dialog_mobile-menu {
  @apply p-0 max-md:rounded-none;
}

.modal-dialog_mobile-menu > .modal-content {
  @apply max-md:max-h-[70dvh] max-md:min-h-min;
}

.modal-content {
  @apply max-h-[90vh] overflow-y-auto p-0 max-md:max-h-[75vh] max-md:p-4;
}

/* Datepicker */

.ngb-datepicker .ngb-dp-week {
  @apply mb-4 flex gap-x-3 last:mb-0;
}

.ngb-datepicker .ngb-dp-day {
  @apply h-6 w-6;
}

.ngb-datepicker .ngb-dp-day.disabled {
  @apply opacity-60;
}

.ngb-datepicker .ngb-dp-months {
  @apply flex gap-x-4;
}
