/* Pagination */
ngb-pagination > .pagination {
  @apply flex items-center justify-center gap-2;
}

ngb-pagination .page-item {
  @apply h-9 w-9 rounded-lg bg-red-50 text-sm font-medium text-text-secondary hover:bg-green-400 hover:text-text-secondary dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

ngb-pagination .page-item > a {
  @apply flex h-full w-full items-center justify-center;
}

ngb-pagination .page-item.active {
  @apply bg-green-400 text-white;
}

ngb-pagination .page-item.disabled {
  @apply pointer-events-none bg-transparent opacity-80;
}

ngb-pagination .page-item > a[aria-label="First"],
ngb-pagination .page-item > a[aria-label="Previous"],
ngb-pagination .page-item > a[aria-label="Next"],
ngb-pagination .page-item > a[aria-label="Last"] {
  @apply rounded-lg bg-transparent text-slate-400 hover:bg-green-400 hover:text-white dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

ngb-pagination a[aria-label="Previous"] span {
  @apply hidden;
}

ngb-pagination a[aria-label="Previous"]::before {
  content: "";
  background-image: url("/assets/icons/chevron-down.svg");
  background-size: cover;
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.8;
}

ngb-pagination a[aria-label="Next"] span {
  @apply hidden;
}

ngb-pagination a[aria-label="Next"]::before {
  content: "";
  background-image: url("/assets/icons/chevron-down.svg");
  background-size: cover;
  transform: rotate(-90deg);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.8;
}

/* Table */
.adminTable {
  @apply w-full;
}

.adminTable th:first-of-type {
  @apply rounded-l-2xl;
}
.adminTable th:last-of-type {
  @apply rounded-r-2xl;
}

.adminTable > thead tr {
  @apply bg-red-50 dark:border-slate-700 dark:bg-slate-800;
}

.adminTable > thead th {
  @apply p-3 text-left text-sm font-medium text-slate-400 dark:text-slate-200;
}

.adminTable > tbody tr {
  @apply border-b border-gray-50 hover:bg-green-100 dark:border-slate-700 hover:dark:bg-slate-800;
}

.adminTable > tbody td {
  @apply px-3 py-5 text-left text-sm font-medium;
}

.adminTable > tbody td > div {
  @apply flex items-center justify-start gap-2;
}

.adminTable > tbody td span {
  @apply truncate;
}
